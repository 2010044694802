/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import '~@ionic/angular/css/core.css';

/* Basic CSS for apps built with Ionic */
@import '~@ionic/angular/css/normalize.css';
@import '~@ionic/angular/css/structure.css';
@import '~@ionic/angular/css/typography.css';
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import '~@ionic/angular/css/padding.css';
@import '~@ionic/angular/css/float-elements.css';
@import '~@ionic/angular/css/text-alignment.css';
@import '~@ionic/angular/css/text-transformation.css';
@import '~@ionic/angular/css/flex-utils.css';

//Fuentes
@font-face {
  font-family: 'black-ops';
  src: url('assets/family-font/black-ops-one/BlackOpsOne-Regular.ttf');
}

@font-face {
  font-family: 'roboto';
  src: url('assets/family-font/roboto/Roboto-Regular.ttf');
}
@font-face {
  font-family: 'black-ops';
  src: url('assets/family-font/black-ops-one/BlackOpsOne-Regular.ttf');
}
@font-face {
  font-family: 'anonimus-pro';
  src: url('assets/family-font/anonymous-pro/AnonymousPro-Regular.ttf');
}
:root {
  /*COLOR THEME SECTION*/

  // - Theme A - Background

 // --background-gradient-theme-a: linear-gradient(
 //   0deg,
 //   rgb(48, 71, 67) 10%,
 //   rgba(105, 170, 158, 1) 100%
//  );
  --background-gradient-theme-a:rgb(0,0,0);

  --background-content-color-theme-a: rgb(0, 0, 0);
  //--background-content-color-theme-a: rgb(0, 0, 0);
  --background-toolbar-color-theme-a: rgb(0, 0, 0);
  --background-header-bar-color-theme-a: rgb(0, 0, 0);
 // --background-item-color-bar-theme-a: rgb(38, 77, 70);
  --background-item-color-bar-theme-a: rgb(0, 0, 0);
 // --background-item-color-theme-a: rgb(78, 119, 111);
  --background-item-color-theme-a: rgb(12, 15, 65);
  --background-item-color-theme-b:rgb(255, 255, 255);
  --background-item-color-light-theme-a: rgb(60, 119, 107);

  // - Theme A - Background - Buttons

  --background-button-color-footer-selected-theme-a: rgb(12,15,65
  ); // por si se usará
  //--background-button-color-item-selected-theme-a: rgb(78,119,111);
  // --background-button-color-content-theme-a: rgb(24, 36, 34);
  --background-button-color-content-theme-a: rgb(12, 15, 65);
  //--background-button-color-content-selected-theme-a: rgb(78, 119, 111);
  //--background-button-color-content-selected-theme-a: rgb(0,0,0);
  --background-button-color-toolbar-theme-a: rgb(0,0,0);
  --background-button-color-toolbar-selected-theme-a: rgb(78, 119, 111);

  --background-button-color-content-wallet-theme-a: rgb(0, 81, 243);


  // - Theme A - Text
  --text-color-theme-a: rgb(255, 255, 255);

  // - Theme Corp
   --background-item-color-bar-theme-c: rgb(0, 0, 0);
  // --background-item-color-bar-theme-c: rgb(38, 77, 70);
  // --background-media-color-bar-theme-c: rgb(163, 139, 0);
  --background-media-color-bar-theme-c: rgb(255, 255, 255);
  //laniakea
  --background-media-color-bar-theme-l: rgb(32, 27, 104);

  /*COLOR LIKE AND COMMENT*/
  --like-color-strong: rgb(255, 255, 255);
  --alert-color: rgb(218, 38, 38);
  --send-color: rgb(178, 238, 230);
  --contrast-icon-color: rgb(255, 201, 14);
}
.detailsInModal{

  width:100%;
  height:100%;
  --border-radius: 20px;
  border-radius: 48px;
  --border-color:white;
  --border-style:solid;
  --border-width: 3px;
  --border:solid 3px white;
  border:solid 3px white;
 //background-color: black;
  //background-image: url("https://laniakea-multimedia.s3.amazonaws.com/GregariousEssentialElver-size_restricted.gif");
  --backdrop-opacity: 1;
  backdrop-filter: blur(3px);



}
.my-custom-modal-css .modal-wrapper {
  --border-radius: 20px;
  
  height: 33%;
  bottom: 0;
  position: absolute;
  display: block;
  text-align: center;
  border-top-right-radius: 15px;
  border-top-left-radius: 15px;
  background-color: black;
}

.loading-controller-class {
  --background: var(--background-toolbar-color-theme-a);
  --spinner-color: var(--background-item-color-theme-a);
  color: var(--ion-text-color);
}

.options-sheet {
  --background: transparent !important; /* Background color */
  --button-color: white; /* Button text color */
  --color: white !important;
  
  max-width: 300px;
 // max-height: 360px;
  position: absolute;
  
  /* Centering */
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

ion-popover {
  --background: black;
  --backdrop-opacity: 0.6;
  --box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.6);
  --color: white;
  backdrop-filter: blur(3px);
  --width: 300px;

  --border-color:white;
  --border-style:solid;
  --border-width: 3px;
  --border:solid 3px white;
  border:solid 3px white;
  --border-radius: 21px;
  border-radius: 21px;
}

ion-popover ion-content {
  --background:black;
}
ion-popover::part(container) {
  --color: white;
  border-radius: 48px;
  border:solid white;

  box-shadow: 4px 0px 16px rgba(255, 0, 255, 0.18);
}
ion-popover::part(backdrop) {
  border-radius: 48px;
  border:solid white;
  box-shadow: 4px 0px 16px rgba(255, 0, 255, 0.18);
  background-color: rgb(6, 14, 106);
}
.relationConfrmation {
  --color: white;
  --border-color:white;
  --border-style:solid;
  --border-width: 3px;
  --border:solid 3px white;
  border:solid 3px white;
  --border-radius: 20px;
  border-radius: 21px;
  --background: var(
    --ion-background-color
  ) !important;
  .alert-button.sc-ion-alert-md,
  .alert-button.sc-ion-alert-ios {
    color: var(--ion-text-color);
  }
  .alert-title.sc-ion-alert-md,
  .alert-title.sc-ion-alert-ios {
    font-size: 16;
    color: var(--ion-text-color);
  }
  .alert-message.sc-ion-alert-md,
  .alert-message.sc-ion-alert-ios {
    color: var(--ion-text-color);
  }
  .alert-input.sc-ion-alert-md,
  .alert-input-wrapper.sc-ion-alert-md {
    color: var(--text-color-theme-a);
  }
  
}

.notificationsPopOver {
  --background: var(
    --background-button-color-footer-selected-theme-a
  ) !important;
  ion-backdrop {
    opacity: 0.5 !important;
  }
  .popover-wrapper {
    .popover-content {
      width: 80vw;
      max-width: 500px;
    }
  }
}
ion-segment-button{
  border-radius: 9px;
  border: solid 0.000000009px darkgray;
  --background-checked: #010f4e;
color:white;
  --background-focused-opacity: 100%;
  --background-hover: #010f4e;
  --background-focused: #010f4e;
  --background-hover-opacity: 100%;
  --indicator-color: #010f4e;
  text-transform: capitalize;
}

.centerPopOver {
  --background: var(
    --background-button-color-footer-selected-theme-a
  ) !important;
  div.popover-content {
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;
    display: contents;
  }
  ion-backdrop {
    opacity: 0.5 !important;
  }
  .popover-wrapper {
    .popover-content {
      width: 80vw;
      max-width: 500px;
    }
  }
}

.center-items{
  display: flex;
  justify-content: center;
}
.text-center{
  text-align: center;
}
.align-center{
  align-items: center;
}
.displayflex{
  display: flex;
}
.alignself{
  align-self: center;
}

.header-text{
  font-family: 'black-ops';
  color: rgb(12, 15, 65);
}

.black-ops{
   font-family: 'black-ops';
}

.diseño-input-select{
  border: solid white 2px;
  border-radius: 10px;
  height: 40px;
  width: 100%;
  display: flex;
  justify-content: center;
  background: var(--ion-card-background);
}

.diseño-button-siguiente{
  position: static;
width: 12rem;
height: 3rem;
left: 40px;
top: 14.5px;
--background: #010f4e;
--border-radius: 20px;
font-family: 'black-ops';
font-style: normal;
font-weight: normal;
font-size: 15px;
line-height: 31px;
display: flex;
align-items: center;
text-align: center;
color: #FFFFFF;
flex: none;
order: 0;
flex-grow: 0;
margin: 0px 10px;

}
.footer-publications{
  display: flex;
  justify-content: center;
  align-items:center;
  margin: 5px;
  padding: 15px;
}
.wrapper-full-display{
  display: flex;
  flex-direction: column;
  position:absolute;
  left:0;
  right:0;
  top:0;;
  bottom:0;
}

.mapa {
  display: flex;
  justify-content: center;
  height: 50%;

}


.icon .sc-ion-action-sheet-md{
  color: white;
}

.title-action .sc-ion-action-sheet-md{
  color: var(--ion-text-color);
  text-align: center;
}
/* Set the background color for the entire app to black */
/* Add this to your global.scss */
.custom-action-sheet {
  
  .title-action {
    background-color: black; /* Set the background color to black */
    border-radius: 48px;
  }

  
  .icon {
    color: white; /* Set the icon color to white */
  }
}
ion-action-sheet{
  width:100%;
  --height:100%;
  --button-background-activated:#010f41;
  --button-background-selected:#010f41;
  --button-background-hover-opacity:1;
  --button-background-selected-opacity:1;
  --button-color:black;
  height:100%;
  --border-radius: 21px;
  border-radius: 48px;
  --border-color:white;
  --border-style:solid;
  --border-width: 3px;
  --border:solid 3px white;
  border:solid 3px white;


 //background-color: black;
  //background-image: url("https://laniakea-multimedia.s3.amazonaws.com/GregariousEssentialElver-size_restricted.gif");
  --backdrop-opacity: 1;
  backdrop-filter: blur(3px);
  //background-image: url('https://laniakea-multimedia.s3.amazonaws.com/imagenes/pillars-of-creation-james-webb-21-9-5974x2560-v0-tl4zsrio9wu91.png');
  //background-size: cover;
  position:fixed;
//margin-top: 15%;
  //height: 81vh;

  border-radius: 9px;
  border: solid 6px darkgray;
}
ion-item{
  border-radius: 9px;
  padding:3.3px;
  border: solid darkgray;

}
ion-label{
  font-family: "anonimus-pro" !important;
}
label{
  font-family: "anonimus-pro" !important;
}

/* Change the color of the pager bullets for all ion-slides */
ion-slides {
  --bullet-background: #fff !important; /* white color for non-active slides */
  --bullet-background-active: #010f4e !important; /* blue color for the active slide */
  --bullet-border-radius: 3px !important; /* circular bullets */
  --bullet-box-shadow: 0 0 0 2px #fff !important; /* white border around bullets */
  --progress-bar-background:white !important;
  --progress-bar-background-active:#010f4e !important;
  --scroll-bar-background:white !important;
  --scroll-bar-background-active:#010f4e !important;

}
ion-toggle {

  padding: 12px;

  --track-background: #010f41;
  --track-background-checked: #010f41;

  --handle-background: #eb7769;
  --handle-background-checked: #95c34e;

  --handle-width: 25px;
  --handle-height: 27px;
  --handle-max-height: auto;
  --handle-spacing: 6px;

  --handle-border-radius: 4px;
  --handle-box-shadow: none;
}

ion-toggle::part(track),
ion-toggle.toggle-checked::part(track)  {
  height: 10px;
  width: 51px;
  background: #010f41;
  border:solid gray;
  /* Required for iOS handle to overflow the height of the track */
  overflow: visible;
}
ion-range {
  --bar-background: red;
  --bar-background-active: green;
  --bar-height: 8px;
  --bar-border-radius: 8px;
  --knob-background: #010f41;
  --knob-size: 40px;
  --pin-background: #010f41;
  --pin-color: #fff;
}
ion-range::part(pin) {
  display: inline-flex;
  align-items: center;
  justify-content: center;

  background: #010f41;
  color: #fff;

  border-radius: 3px;
  border:solid gray;
  transform: scale(1.01);

  top: -0px;

  min-width: 28px;
  height: 9px;
  transition: transform 120ms ease, background 120ms ease;
}
.html5-qrcode-element {
  background-color: #010f41; // Set background color
  font-size: 30px; // Increase font size
  color: #ffffff; // Set text color to white for better contrast
  padding: 10px 20px; // Add padding for a bigger button
  border: none; // Remove default border
  border-radius: 5px; // Add rounded corners
  cursor: pointer; // Change cursor to pointer on hover
  display: inline-block;

  &:hover {
    background-color: #010f4e; // Darker shade on hover
  }

  &:focus {
    outline: none; // Remove default focus outline
    box-shadow: 0 0 0 2px #000; // Add custom focus outline
  }
  font-family: "black-ops" !important;
}

h1{
  font-family: 'black-ops';
}
ion-content {
  --overflow: auto; /* Enables scrolling */
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE/Edge */
}
ion-content::-webkit-scrollbar {
  display: none; /* Hides scrollbar in Chrome/Safari */
}
::-webkit-scrollbar {
  display: none;
  width: 0px;
  height: 0px;
}
* {
  scrollbar-width: none;
}

/* Hide scrollbar in Edge and IE */
* {
  -ms-overflow-style: none; /* IE and Edge */
  -ms-overflow-style: none;
}
html, body, ion-content, .scrollable-container {
  overflow: auto !important; /* Enables scrolling */
  -webkit-overflow-scrolling: touch; /* Ensures smooth scrolling on iOS */
}
ion-header{
  border-radius: 24px;
  --border-radius: 24px;
  border: solid 3px #010f41;
  --border:solid 3px #010f41;
  object-fit: contain;
  overflow: hidden;
}
ion-footer{
  border-radius: 24px;
  --border-radius: 24px;
  border: solid #010f41;
  --border:solid 3px #010f41;
  object-fit: contain;
  overflow: hidden;
}